<template>
  <div class="reports-page">
    <div v-if="!isFormActive" class="header">
        Reports
    </div>
    <div v-else class="header">
        {{$t(activeReportTitle.Value)}}
    </div>
    <div v-if="isReportsLoading" class="loading">
      <i class="fa fa-fw fa-spinner fa-spin"></i>{{$t("Loading")}}...
    </div>
    <div v-else-if="isReportDownloading" class="loading">
      <i class="fa fa-fw fa-spinner fa-spin"></i>{{$t("downloading_report")}}...
    </div>
    <div v-else-if='!isReportsLoading && !isFormActive'>
      <div class="container">
        <ReportSelection
         v-for="report in reportsObject"
         :key="report.key"
         :name="report.key"
         :ref="report.title"
         :title="report.title"
         :description="report.description"
         :formName="report.formName"
        ></ReportSelection>
      </div>
    </div>
    <div v-else class="report-container" >
      <Form
        id="ReportsFilters"
        :key="activeReportTitle.Form"
        :name="activeReportTitle.Form"
        :isEditing="true"
        :loadFormData="false"
        :saveFormUrlParams="'?reportTitle=' + activeReportTitle.Key + '&languageCode=' + languageCode"
        />
      <Button v-if='!this.isLoading' id="ReportsFilterCancelBtn" @click="endReportActive" color="primary" type="outlined" :label="$t(`Cancel`)">
        <i class="fa fa-fw fa-ban"></i>
      </Button>
    </div>
  </div>
</template>

<script>
import ReportSelection from './components/ReportSelection.vue'
import Form from '../../../shared/components/Form'
import { mapState, mapActions } from 'vuex'
import EventBus from '../../../shared/event-bus'
import Button from '@/app/shared/components/Button'
import { getCookie } from '../../../../utils/cookieUtils'
export default {
  name: 'Reports',
  metaInfo: {
    title: 'Reports'
  },
  data: self => ({
    languageCode: null
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  async mounted () {
    await this.getReportData()
    this.setLanguageCode()
  },
  components: {
    ReportSelection,
    Form,
    Button
  },
  methods: {
    ...mapActions('Reports', ['getReportData', 'endReportActive', 'setActiveReport']),
    /* istanbul ignore next */
    formSavedSuccessfully (data) {
      var Data = data.response

      if (this.activeReportTitle.Key !== 'DetailReport') {
        if (Data) {
          const anchor = document.createElement('a')
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(Data.data.reportContent)
          anchor.download = Data.data.reportName + '.csv'
          anchor.click()
        }
      } else {
        const anchor = document.createElement('a')
        anchor.href = Data.data.reportContent
        anchor.download = Data.data.reportName + '.csv'
        anchor.click()
      }
      this.setActiveReport(null)
    },
    setLanguageCode () {
      this.languageCode = getCookie('languageCode')
    }
  },
  computed: {
    ...mapState('Reports', [
      'isReportsLoading',
      'reportsObject',
      'isFormActive',
      'activeReportTitle',
      'isReportDownloading'
    ]),
    ...mapState('Form', ['isLoading'])
  }
}
</script>

<style lang="scss" scoped>
#ReportsFilterCancelBtn {
  position: absolute;
  bottom: -70px;
  left: 40px;
}
.report-container {
  position: relative;
}
.header{
  font-family: 'IBM Plex Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  padding-bottom: 20px;
  align-items: center;
  letter-spacing: 0.005em;
  color: var(--day-select-color);
  padding-left: 10px;
}
.loading {
  padding-left: 10px;
}
.container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}
</style>
